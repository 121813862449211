#projects-section {
  padding: 15rem 5rem;
}

.each-project {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 20rem;
}

.last-project {
  margin-bottom: 0;
}

.each-project img {
  width: 100%;
  box-shadow: 0px 0px 40px -10px #000000;
  grid-row: 1/2;
  border-radius: 10px;
}

.project-img-anchor {
  width: 100%;
  grid-row: 1/2;
  grid-column: 1 / 8;
  transition: 0.5s;
}

.project-img-anchor:hover {
  transform: translateY(-5px);
}

.project-info {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-row: 1/2;
  z-index: 5;
}

.project-head {
  display: flex;
  flex-direction: column;
}

.featured-project {
  font-size: var(--fz-sm);
  font-family: var(--font-fira);
  color: var(--green);
  margin-bottom: 1rem;
}

.project-name {
  font-weight: 700;
  font-size: var(--fz-xxxl);
  text-decoration: none;
  color: var(--white);
}

.project-name:hover {
  color: var(--green);
}

.project-description {
  background-color: var(--light-navy);
  font-size: var(--fz-sm);
  padding: 2rem 2rem 2rem 3rem;
  line-height: 1.6;
  color: var(--light-slate);
  border-radius: 10px;
}

.project-info ul {
  list-style: none;
  display: flex;
  row-gap: 0.2rem;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.project-info li {
  font-size: var(--fz-sm);
  color: var(--light-slate);
  font-family: var(--font-fira);
}

.project-socials-icons {
  color: var(--light-slate);
}

.project-socials-icons:hover {
  color: var(--green);
}

/* REUSEABLE COMPONENTS */

.project-img-odd {
  grid-column: 1 / 8;
}

.project-img-even {
  grid-column: 6 / 13;
}

.project-img-anchor-even {
  grid-column: 6 / 13;
}

.project-info-odd {
  align-items: flex-end;
  grid-column: 7 / 13;
}

.project-info-even {
  align-items: flex-start;
  grid-column: 1 / 8;
}

.project-head-odd {
  align-items: flex-end;
}

.project-head-even {
  align-items: flex-start;
}

.project-description-odd {
  text-align: right;
}

.project-description-even {
  text-align: left;
}

.project-socials-icons-odd {
  margin-left: 2rem;
}

.project-socials-icons-even {
  margin-right: 2rem;
}

@media (max-width: 500px) {
  #projects-section {
    padding: 0 5%;
    /* padding-bottom: 15rem; */
    padding-top: 10vh;
  }

  #projects-section .horizontal-line {
    width: 3rem;
  }

  .project-img-anchor {
    display: none;
  }

  .featured-project {
    font-size: var(--fz-xxs);
  }

  .project-name {
    font-size: var(--fz-xxl);
  }

  .project-description {
    font-size: var(--fz-xs);
    background: none;
    padding: 0;
  }

  .each-project {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    border-radius: 2rem;
    overflow: hidden;
  }

  .project1 {
    background: url('../img/OpenRoad2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project2 {
    background: url('../img/toDoList.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project3 {
    background: url('../img/Course-Recommender.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: rgba(17, 34, 64, 0.9);
    padding: 2rem;
  }

  .project-info li {
    font-size: var(--fz-xs);
    color: var(--light-slate);
    font-family: var(--font-fira);
  }

  /* REUSEABLE COMPONENTS */

  .project-info-odd {
    align-items: flex-start;
  }

  .project-info-even {
    align-items: flex-start;
  }

  .project-head-odd {
    align-items: flex-start;
  }

  .project-head-even {
    align-items: flex-start;
  }

  .project-description-odd {
    text-align: left;
  }

  .project-description-even {
    text-align: left;
  }

  .project-socials-icons-odd {
    margin: inherit;
    margin-right: 2rem;
  }

  .project-socials-icons-even {
    margin-right: 2rem;
  }
}
