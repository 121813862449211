header {
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(10, 25, 47, 0.85);
  position: fixed;
  top: 0;
  padding: 3rem 3%;
  backdrop-filter: blur(10px);
  z-index: 10;
}

.logo img {
  width: 5rem;
}

.nav-menu {
  list-style: none;
  display: flex;
  gap: 4rem;
}

.nav-item {
  text-decoration: none;
  color: var(--lightest-slate);
  font-family: var(--font-fira);
  font-size: var(--fz-sm);
  cursor: default;
}

.nav-item-number {
  color: var(--green);
  margin-right: 0.5rem;
  font-family: var(--font-fira);
}

.resume-btn:hover {
  cursor: pointer;
}

.toggle-button {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  height: 30px;
  width: 30px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
}

@media (max-width: 500px) {
  .logo img {
    width: 3rem;
  }

  header {
    padding: 5%;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--light-navy);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--fz-lg);
  }

  .nav-item-number {
    margin: 0;
  }

  .toggle-button {
    display: flex;
    z-index: 10;
  }

  .nav-menu {
    /* display: none; */
    padding-bottom: 10rem;
  }

  .nav-menu.active {
    display: flex;
  }

  .bar1.active {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: rotate(45deg);
  }

  .bar2.active {
    visibility: hidden;
  }

  .bar3.active {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: rotate(-45deg);
  }
}
