#work-section {
  padding: 15rem 5rem;
  max-width: 850px !important;
  /* min-height: 700px; */
}

.work-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.work-names {
  display: flex;
  flex-direction: column;
}

.work-button {
  background: none;
  border: none;
  font-size: var(--fz-sm);
  color: var(--slate);
  padding: 1rem 2rem;
  border-left: 2px solid var(--slate);
  text-align: left;
  transition: 0.5s;
  font-family: var(--font-fira);
}

.work-button:hover {
  background-color: var(--light-navy);
  border-left: 2px solid var(--green);
  color: var(--green) !important;
  cursor: pointer;
}

.work-body {
  grid-column: 2/5;
}

@media (max-width: 500px) {
  #work-section {
    padding: 0 5%;
    /* padding-bottom: 15rem; */
    padding-top: 10vh;
    /* min-height: 750px; */
  }

  #work-section .horizontal-line {
    width: 5rem;
  }

  .work-names {
    flex-direction: row;
    align-items: flex-start;
    overflow-x: scroll;
  }

  .work-button {
    border-bottom: 2px solid var(--slate);
    border-left: none;
  }

  .work-button:hover {
    background-color: var(--light-navy);
    border-left: none;
    border-bottom: 2px solid var(--green);
    color: var(--green) !important;
    cursor: pointer;
  }

  .work-div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}
