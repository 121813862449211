#contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 15rem 5rem;
}

.contact-heading {
  font-size: var(--fz-md);
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  color: var(--green);
}

.contact-heading-number {
  font-family: var(--font-fira);
}

.contact-heading-text {
  font-family: var(--font-fira);
}

.contact-GIT {
  font-weight: 600;
  font-size: 60px;
  color: var(--lightest-slate);
}

.contact-text {
  font-size: var(--fz-md);
  text-align: center;
  width: 600px;
}

.say-hello {
  margin-top: 5rem;
  font-size: var(--fz-md);
  font-family: var(--font-fira);
}

@media (max-width: 500px) {
  #contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0 5%;
    padding-bottom: 15rem;
    padding-top: 20vh;
  }

  .contact-GIT {
    font-size: 40px;
  }

  .contact-heading-text {
    font-size: var(--fz-lg);
  }

  .contact-text {
    font-size: var(--fz-sm);
    text-align: center;
    width: 100%;
  }
}
