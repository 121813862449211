#about-section {
  padding: 12rem 5rem 15rem 5rem;
}

.about-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 5rem;
  gap: 2rem;
}

.about-left {
  grid-column: 1/4;
  font-size: var(--fz-smd);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  line-height: 1.3;
}

.about-skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about-skills ol {
  list-style: none;
}

.about-skills li {
  font-family: var(--font-fira);
  font-size: var(--fz-sm);
}

.about-skills li::before {
  content: '>';
  margin-right: 1rem;
  color: var(--green);
}

.about-right {
  grid-column: 4/6;
  display: flex;
  justify-content: center;
}

.developer {
  width: 30rem;
  position: relative;
  align-self: flex-start;
  box-shadow: 0px 0px 40px -10px #000000;
  border-radius: 10px;
}

/* .description {
  letter-spacing: 0.5px;
} */

@media (max-width: 500px) {
  #about-section {
    padding: 0 5%;
    /* padding-bottom: 15rem; */
    padding-top: 10vh;
  }

  .about-div {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
}
