@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600,700,800&family=Inter:wght@400;500;600&display=swap');

:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --pink: #f57dff;
  --blue: #57cbff;
  --font-inter: 'Inter', sans-serif;
  --font-fira: 'Fira Code', monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-smd: 15px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-xxxl: 28px;
  --fz-xxxxl: 45px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --nav-height: 100px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system,
    system-ui, sans-serif;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--navy);
  color: var(--slate);
  letter-spacing: -0.3px;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lightest-navy);
  border-radius: 5px;
}

.container {
  max-width: 100rem;
  margin: 0 auto;
}

.primary-btn:link,
.primary-btn:visited {
  text-decoration: none;
  color: var(--green) !important;
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  padding: 15px 20px;
  background: transparent;
  transition: 0.5s;
}

.primary-btn:hover,
.primary-btn:active {
  background-color: var(--green-tint);
}

.vertical-line {
  width: 0.1rem;
  height: 10rem;
  background: var(--white);
}

.horizontal-line {
  width: 20rem;
  height: 0.5px;
  background: var(--lightest-navy);
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
}

.section-title-number {
  color: var(--green);
  font-family: var(--font-fira);
  font-size: var(--fz-xxl);
}

.section-title-body {
  margin: 0 2rem;
  color: var(--lightest-slate);
  font-size: var(--fz-xxxl);
  font-weight: 600;
}

.anchor-effect {
  position: relative;
}

.anchor-effect:hover {
  color: var(--green);
  cursor: pointer;
}

.anchor-effect::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.3rem;
  background-color: var(--green);
  height: 2px;
  width: 0;
  transition: 0.5s;
}

.anchor-effect:hover::after {
  width: 100%;
}

@media (max-width: 500px) {
  html {
    /* font-size: 8px; */
  }

  .horizontal-line {
    width: 16rem;
    margin-left: 0.5rem;
  }

  .section-title-number {
    font-size: var(--fz-md);
  }

  .section-title-body {
    font-size: 2.5rem;
    margin: 0 1rem;
    font-weight: 700;
  }
}
