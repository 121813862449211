.socials-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 3.2%;
  gap: 3rem;
  color: var(--white);
}

.socials-icons {
  color: var(--lightest-slate);
  transition: 0.5s;
}

.socials-icons:hover {
  color: var(--green);
  transform: translateY(-5px);
}

@media (max-width: 500px) {
  body {
    position: relative;
  }
  .socials-div {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 10rem;
    flex-direction: row;
  }

  .socials-div .vertical-line {
    display: none;
  }
}
