.home {
  height: 100vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
}

.hi {
  font-family: var(--font-fira);
  font-size: var(--fz-lg);
  color: var(--green);
}

.name {
  font-size: 7rem;
  font-weight: 600;
  color: var(--lightest-slate);
}

.role {
  font-size: 7rem;
  font-weight: 600;
  color: var(--slate);
}

.description {
  font-size: 1.5rem;
  max-width: 50rem;
  color: var(--slate);
  line-height: 1.6;
  margin-top: 1rem;
}

.description a {
  color: var(--green);
  text-decoration: none;
}

.home-btn {
  align-self: flex-start;
  display: inline-block;
  font-size: var(--fz-lg);
  margin-top: 5rem;
}

@media (max-width: 500px) {
  .home {
    height: 90vh;
  }

  .hi {
    font-size: var(--fz-md);
  }
  .name {
    font-size: 3rem;
  }

  .role {
    font-size: 3rem;
  }

  .home {
    padding: 0 2rem;
  }

  .home-btn {
    font-size: var(--fz-sm);
  }
}
