.job-title-div {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.job-title {
  font-size: var(--fz-xxl);
}

.job-role {
  color: var(--lightest-slate);
  font-weight: 500;
  margin-right: 1rem;
}

.job-at {
  color: var(--green);
}

.job-place {
  color: var(--green);
  text-decoration: none;
}

.job-duration {
  font-size: var(--fz-xs);
  color: var(--light-slate);
  font-family: var(--font-fira);
}

.job-description {
  list-style: none;
  font-size: var(--fz-smd);
  margin-left: 4rem;
  position: relative;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.job-description::before {
  content: '>';
  color: var(--green);
  position: absolute;
  left: -4rem;
}
