.email-div {
  position: fixed;
  right: 3.2%;
  bottom: 0;
  writing-mode: vertical-lr;
  display: flex;
  gap: 4rem;
  align-items: center;
}

.email-div a:hover {
  color: var(--green);
}

.email-div a {
  font-size: var(--fz-xs);
  color: var(--lightest-slate);
  font-family: var(--font-fira);
  text-decoration: none;
}

@media (max-width: 500px) {
  .email-div {
    display: none;
  }
}
