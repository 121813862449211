#footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15rem 5rem 5rem 5rem;
  /* margin-top: 20rem; */
}

#footer-section p {
  font-family: var(--font-fira);
  color: var(--light-slate);
  font-size: var(--fz-sm);
}

#footer-section a {
  font-family: var(--font-fira);
  color: var(--light-slate);
  text-decoration: none;
}

#footer-section a:hover {
  color: var(--green);
}

@media (max-width: 500px) {
  #footer-section {
    padding: 5rem 5%;
  }

  #footer-section p {
    font-size: var(--fz-xxs);
  }
}
